<template>
	<div class="wrapper">
		<div class="view" ref="view" @click="growImg($event)"></div>
		<div class="select">
			<img @click="openUpload" src="@/assets/image/Customerservice_Sendpictures@2x.png" />
			<template>
				<el-upload v-show="imgUrl != ''" :action="imageUrl" list-type="picture-card" :on-success="UserImgSuccess"
				 :on-remove="UserImgtRemove">
					<i class="el-icon-plus"></i>
				</el-upload>
			</template>
		</div>
		<div class="input">
			<textarea :disabled="disabled" @keydown="entrySend($event)" ref="text" rows="5" name="S1" cols="20" />
			<button @click="sendText">发送</button>
    </div>
    <div class="mask" v-if="showImgWrap">
      <img :src="showImgUrl" alt />
      <i class="el-icon-close close" @click="closeImg"></i>
    </div>
  </div>
</template>

<script>
import { usergetinfo } from "@/request/user";
import { mapState } from "vuex";
export default {
  props: ["inquiry_doctor_id", "inquiry_id"],
  data() {
    return {
      imgUrl: "",
      isShow: true,
      disabled: false,
      showImgUrl: "",
      showImgWrap: false,
      userMessage: {}, // 医生药师传递的信息
      preMessageText: "",
      preImgUrl: ""
    };
  },
  created() {
    usergetinfo({ token: this.$token }).then(res => {
      if (res.code == 1) {
        if (res.data.avatar) {
          this.myAvatar = res.data.avatar;
        } else {
          this.myAvatar = require("@/assets/image/user.png");
        }
      } else {
        this.myAvatar = require("@/assets/image/user.png");
      }
    });
    let chat_in = {
      method: "chat_in",
      data: {
        inquiry_doctor_id: this.inquiry_doctor_id,
        inquiry_id: this.inquiry_id
      }
    };
    this.$store.state.sendMessage(JSON.stringify(chat_in));
  },
  watch: {
    retMessage() {
      // 直接监听store中的数据变化
      let retMessage = this.$store.state.retMessage;
      if (retMessage.message_type == "sys_doctor") {
        this.renderTransferDoctor(retMessage.doctor_list);
        return;
      }
      if (retMessage.message || retMessage.pic) {
        this.userMessage = retMessage;
        this.userTransmitMessage();
      }
    }
  },
  computed: {
    ...mapState(["doctor_info", "retMessage"])
  },
  methods: {
    sendText() {
      if (this.imgUrl != "") {
        this.sendImg();
      }
      if (this.$refs.text.value.trim() == "") return;
      // 给父组件传递聊天对话信息
      this.sendMessage(this.$refs.text.value);

      // 读取img图片的数据
      let div = document.createElement("div");
      div.innerHTML = `<div> <img style="width: 40px; height: 40px" src="${this.myAvatar}"/>
                           <span> ${this.$refs.text.value} </span> </div>`;
      div.className = "doctor chatbox user1";
      this.$refs.view.appendChild(div);
      this.$refs.text.value = "";
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
    },
    sendImg() {
      this.sendMessage(this.imgUrl);
      let div = document.createElement("div");
      div.innerHTML = `<div> <img style="width: 40px; height: 40px"  src="${this.myAvatar}"/>
                           <span class='img'> <img src='${this.imgUrl}'/> </span> </div>`;
      div.className = "doctor chatbox user1";
      this.$refs.view.appendChild(div);
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
      this.imgUrl = ''
    },
    entrySend(e) {
      if (this.$refs.text.value.trim() == "") return;
      if (e.keyCode === 13) {
        this.sendText();
      }
    },
    UserImgSuccess(response) {
      this.imgUrl = response.data.file.url;
    },
    UserImgtRemove() {
      this.imgUrl = "";
    },
    openUpload() {
      this.imgUrl = "";
      let div = document.getElementsByClassName("el-upload--picture-card")[0];
      div.click();
    },
    // 用户传递的信息
    userTransmitMessage() {
      let avatar;
      if (this.userMessage.user_info && this.userMessage.user_info.avatar) {
        avatar = this.userMessage.user_info.avatar;
      } else {
        avatar = require("@/assets/image/Interrogation_expert_Headportrait@2x.png");
      }
      if (
        this.userMessage.pic &&
        this.userMessage.pic.includes("http://hlwyaodian.jzbwlkj.com")
      ) {
        // 传递的是图片
        if (this.userMessage.pic == this.preImgUrl) return;
        let div = document.createElement("div");
        div.innerHTML = `<div> <img  src="${avatar}"/>
                           <span class='img'> <img src='${this.userMessage.pic}'/> </span> </div>`;
        div.className = "user chatbox";
        this.$refs.view.appendChild(div);
      } else {
        // 传递的是文本信息
        if (this.userMessage.message == this.preMessageText) return;
        let div = document.createElement("div");
        div.innerHTML = `<div> <img  src="${avatar}"/>
                           <span> ${this.userMessage.message} </span> </div>`;
        div.className = "user chatbox";
        this.$refs.view.appendChild(div);
      }
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
    },
    renderTransferDoctor(doctors) {
      let self = this;
      let view = document.getElementsByClassName("view")[1];
      let div = document.createElement("div");
      let ps = "";
      doctors.forEach(item => {
        ps += `<p uid='${item.uid}'> ${item.user_nickname} <i class='wait'> ${item.waiting_num}人排队 </i></p>`;
      });
      div.innerHTML = `<div> <img  src="${"https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1649554161,3942481746&fm=115&gp=0.jpg"}"/>
                           <span>  
                              请选择要转接的医生
                               ${ps}
                           </span> 
                        </div>`;
      div.className = "transfer chatbox";
      view.appendChild(div);
      view.scrollTop = view.scrollHeight;
      div.onclick = function(e) {
        let uid = e.target.getAttribute("uid");
        if (uid) self.transferDoctor(+uid);
      };
    },
    transferDoctor(uid) {
      let obj = {
        method: "get_doctor_list",
        data: {
          inquiry_id: this.inquiry_id,
          doctor_uid: uid
        }
      };
      this.$store.state.sendMessage(JSON.stringify(obj));
    },
    // 放大图片处理函数
    growImg(e) {
      this.main = document.querySelector(".view .main");
      if (e.target.nodeName === "IMG") {
        this.showImgWrap = true;
        this.showImgUrl = e.target.src;
        this.main.style.zIndex = "4";
      }
    },
    closeImg() {
      this.showImgUrl = "";
      this.showImgWrap = false;
      this.main.style.zIndex = "2";
    },
    // 向医生或用户发送信息
    sendMessage(msg) {
      console.log(msg);
      if (msg.includes("http://hlwyaodian.jzbwlkj.com")) {
        this.preImgUrl = msg;
      } else {
        this.preMessageText = msg;
      }
      if (this.$store.state.sendMessage) {
        let chat_message = {
          method: "chat_message",
          data: {
            inquiry_id: this.inquiry_id,
            inquiry_doctor_id: this.inquiry_doctor_id,
            to_uid: this.doctor_info.user_id,
          [msg.includes("https://apponline.sdlzyy.cn") ?
          	"send_img" :
          	"send_text"
          ]: msg
            
            
          }
        };
        this.$store.state.sendMessage(JSON.stringify(chat_message));
      }
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 37.5%;
  height: calc(100% - 55px);
  position: absolute;
  right: 0px;
}
.view {
  position: absolute;
  width: 100%;
  height: calc(100% - 190px);
  border-right: 1px solid #999;
  overflow: scroll;
}
.select {
  width: 37.5%;
  height: 40px;
  background: #ddd;
  position: absolute;
  border: 1px solid #999;
  z-index: 999;
  bottom: 170px;
  width: 100%;
  img {
    width: 22px;
    height: 18px;
    margin-top: 8px;
    margin-left: 20px;
    cursor: pointer;
  }
}
.select /deep/ .el-upload {
  position: absolute;
  top: -160px;
  left: 20px;
}
.select /deep/ .el-upload-list__item {
  position: absolute;
  top: -160px;
  left: 20px;
  z-index: 999;
}
.input {
  width: 100%;
  height: 150px;
  border: 1px solid #999;
  position: absolute;
  bottom: 20px;
  textarea {
    padding-top: 18px;
    width: 100%;
    height: 150px;
    font-size: 22px;
    border: 0px;
  }
  button {
    width: 100px;
    height: 45px;
    border-radius: 12px;
    font-size: 20px;
    position: absolute;
    top: 100px;
    right: 100px;
    cursor: pointer;
    outline: none;
  }
}
.chatbox {
  width: 300px;
  font-size: 30px;
  float: right;
  overflow: hidden;
  text-align: right;
  span {
    float: right;
  }
  img {
    float: right;
  }
}
.mask {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  text-align: center;
  img {
    width: 40%;
    margin-top: 100px;
  }
  .close {
    font-size: 50px;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
